<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Cloud files
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>  / Cloud files
                </div>
              </div>
              <div class="breadcrumb-right" v-if="currentUser.access_type==='federal'">
                <div class="card-toolbar">
                  <v-btn @click="createCloudFile()" class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Upload file
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="7">
                  <v-text-field
                      label="Search by title"
                      v-model="search.title"
                      outlined
                      dense
                      v-on:keyup.enter="searchAllCloudFiles()"
                      @input="search.title = $event !== null ? $event : ''"
                      clearable
                  ></v-text-field>
                </v-col>


                <v-col cols="12" sm="6" md="3">
                  <v-select

                      v-model="search.visibility"
                      v-on:keyup.enter="searchAllCloudFiles"
                      @input="search.visibility = $event !== null ? $event : ''"
                      label="Visibility" :items="[{text:'Public',value:'public'},{text:'Private',value:'private'}]"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>


                <v-col cols="2" class="text-right">
                  <v-btn
                      :loading="loading"
                      @click.prevent="searchAllCloudFiles()"
                      class="btn btn-primary w-35 float-right"
                      dark
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>

              </div>
            </div>
            <div class="table-responsive">
              <v-skeleton-loader type="table-thead" v-if="loading">
              </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="! loading">
                <thead>
                <tr class="px-3">
                  <th class="px-3">Title</th>
                  <th class="px-3">Uploaded by</th>
                  <th class="px-3">Uploaded date</th>
                  <th class="px-3">Visibility</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in cloudFiles" :key="index">
                  <td class="px-3">
                      <span class="font-weight-medium">
                        {{ item.title }}
                      </span>
                  </td>
                  <td class="px-3">
                      <span class="font-weight-medium">
                        {{ item.uploaded_by_full_name }}
                      </span>
                  </td>
                  <td class="px-3">
                      <span class="font-weight-medium">
                        {{ item.uploaded_date }}
                      </span>
                  </td>
                  <td class="px-3">
                      <span class="font-weight-medium">
                        {{ item.visibility_text }}
                      </span>
                  </td>
                  <td class="pr-0 text-center">
                    <template>
                      <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip>
                        <template v-slot:button-content>
                          <i class="ki ki-bold-more-hor"></i>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover min-w-md-250px">
                          <b-dropdown-text tag="div" class="navi-item">
                            <a  class="navi-link"  @click="editCloudFile(item)">
                                  <span class="navi-icon">
                                      <i class="fas fa-edit"></i>
                                  </span>
                              <span class="navi-text">Edit</span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item">
                            <a  class="navi-link"  @click="viewFile(item.file_path)">
                                  <span class="navi-icon">
                                      <i class="fas fa-eye"></i>
                                  </span>
                              <span class="navi-text">View</span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item">
                            <a class="navi-link"  @click="deleteCloudFile(item.id)">
                                  <span class="navi-icon">
                                      <i class="fas fa-trash"></i>
                                  </span>
                              <span class="navi-text">Delete</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                      </b-dropdown>
                    </template>
                  </td>
                </tr>
                <tr v-if="cloudFiles.length === 0">
                  <td class="text-center px-3" colspan="5">
                    <span class="font-weight-bold">
                      No items found.
                    </span>
                  </td>
                </tr>
                </tbody>
              </table>
              <b-pagination
                  v-if="cloudFiles.length > 0"
                  class="pull-right mt-7"
                  @input="getAllCloudFiles"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  :disabled="loading"
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <create-or-update-cloud-file ref="create-update-cloud-file" @refresh="getAllCloudFiles"></create-or-update-cloud-file>
  </v-app>
</template>
<script>
import S3FileService from "@/services/s3-file/S3FileService";
import CreateOrUpdateCloudFile from "@/view/pages/view/cloud-file/CreateOrUpdateCloudFile.vue";
const s3File=new S3FileService();
export default {
  components:{CreateOrUpdateCloudFile},
  data(){
    return{
      search:{
        title:null,
      },
      loading:false,
      cloudFiles:[],
      total: null,
      perPage: null,
      page: null,


    }
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
  methods:{
    viewFile(url) {
      // Create an invisible anchor element
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank"; // Opens in new tab if needed
      link.download = url.split("/").pop(); // Extracts filename from URL

      // Append to the document and trigger click
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
    },
    createCloudFile(){
      this.$refs['create-update-cloud-file'].addNewCloudFile();
    },
    editCloudFile(item){
      this.$refs['create-update-cloud-file'].editNewCloudFile(item);
    },
    deleteCloudFile(id){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            s3File
                .delete(id)
                .then((response) => {
                  this.getAllCloudFiles()
                  this.$snotify.success("File deleted");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                })
                .finally(() => {
                  this.loading = false;
                });
          }
        }
      });
    },
    searchAllCloudFiles(){
      this.page=1;
      this.getAllCloudFiles();
    },
    getAllCloudFiles(){
      this.loading=true;
      s3File
          .paginate(this.search,this.page)
          .then(response => {
            this.cloudFiles=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {

          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
  mounted() {
    this.getAllCloudFiles();
  }
}
</script>