<template>
  <v-dialog v-model="dialog" max-width="700" scrollable>
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{ title }} file</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field outlined dense
                            :error="$v.cloudFile.title.$error"
                            v-model="cloudFile.title">
                <template v-slot:label>
                 Title <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.name">{{ errors.name[0] }}</span>
              <span class="text-danger" v-if="$v.cloudFile.title.$error">This information is required</span>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <label>Description</label>
              <span class="text-danger">*</span>
              <ckeditor :config="editorConfig" v-model="cloudFile.description" >
              </ckeditor>
            </v-col>
            <v-col md="12" v-if="!edit">
              <v-select v-model="cloudFile.visibility" label="Visibility" :items="[{text:'Public',value:'public'},{text:'Private',value:'private'}]"  item-text="text" item-value="value" dense outlined>
              </v-select>
            </v-col>
            <v-col md="12">
              <v-file-input class="mt-3" outlined dense
                  prepend-icon="" :error="$v.cloudFile.file.$error" prepend-inner-icon="mdi-file" v-model="cloudFile.file">
                <template slot="label">
                  Select file to upload <span class="text-danger" v-if="!edit">*</span>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            text x-large @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn x-large dark :loading="loading"
               @click="createOrUpdate()">
          Save
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {required, requiredIf} from "vuelidate/lib/validators";
import S3FileService from "@/services/s3-file/S3FileService";

const s3File=new S3FileService();

export default {
  validations: {
    cloudFile: {
      title: {required},
      file: { required: requiredIf(function () {
          return !this.edit;
        }) },
    }
  },
  data(){
    return{
      loading:false,
      dialog:false,
      title:'',
      errors:[],
      cloudFile:{
        title:null,
        description:null,
        visibility:'public',
        file:null,
      },
      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      },
      edit:false,
    }
  },
  methods:{
    closeDialog(){
      this.dialog=false;
    },
    openDialog(){
      this.dialog=true;
    },
    resetForm(){
      this.$v.$reset();
      this.cloudFile={
        title:null,
        description:null,
        visibility:'public',
        file:null,
      };
    },
    addNewCloudFile(){

      this.title='Upload new';
      this.edit=false;
      this.openDialog();
      this.resetForm();
    },
    editNewCloudFile(item){
      this.title='Edit';
      this.edit=true;
      this.cloudFile=item;
      this.openDialog();
    },
    convertToFormData() {
      let formData = new FormData();
      for (let key in this.cloudFile) {
        if (key === "file" && this.cloudFile[key] != null && this.cloudFile[key] !== undefined) {
          formData.append('file', this.cloudFile[key]);
        }else {
          if(this.cloudFile[key]){
            formData.append(key,this.cloudFile[key]);
          }
        }
      }
      return formData;
    },
    createOrUpdate(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {
      this.loading = true;
      let formData = this.convertToFormData();
      s3File
          .create(formData)
          .then(response => {
            this.$snotify.success("File uploaded");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.errors = err.errors;
            this.$snotify.error("Oops something went wrong");
          })
          .finally(() => {
            this.loading = false;
          });
    },
    update() {
      this.loading = true;
      let formData = this.convertToFormData();
      s3File
          .update(this.cloudFile.id,formData)
          .then(response => {
            this.$snotify.success("File updated");
            this.closeDialog();
            this.resetForm();
            this.$emit('refresh');

          })
          .catch((err) => {
            this.loading = false;
            this.errors = err.errors;
            this.$snotify.error("Oops something went wrong");
          })
          .finally(() => {
            this.loading = false;
          });
    },
  }
}
</script>