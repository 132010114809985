import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class S3FileService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/s3-file';
    }
    paginate(data = {}, index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    create(data) {

        let url = `${this.#api}`;
        return apiService.post(url, data);

    }
    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }
    update(id, data) {
        let url = `${this.#api}/${id}/update`;
        return apiService.post(url, data)
    }
}